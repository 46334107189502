<template>
    <main>

    </main>
</template>
<script>
import axios from '@/plugins/axios'

export default {
    created: function () {
        this.logOut();
    },
    methods: {
        logOut: function () {
            axios.post('/api/auth/logout', {
        }).then(response => {
            this.$store.dispatch('regAuth', {token:''}).then(res => {this.$store.dispatch('userData', {});   window.location.href ="/feed" })
        }).catch(error=>{
            this.$store.dispatch('regAuth', {token:''}).then(res => {this.$store.dispatch('userData', {});  window.location.href ="/feed" })
        })
        }
    }
}
</script>